import React from "react";
import Link from "gatsby-link";

import Layout from "../components/Layout";

export default function FourOFour() {
  return (
    <Layout>
      <div className="site-content_inner fourofour">
        <p>Sorry. Diese URL scheint es nicht zu geben.</p>
        <p>
          <Link to="/">Hier geht's zur Startseite.</Link>
        </p>
      </div>
    </Layout>
  );
}
